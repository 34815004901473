import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import DashboardLayout from "./components/ui/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import Companies from "./pages/company/Companies";
import ScheduledEventss from "./pages/ScheduledEvents";
import Courses from "./pages/courses/Courses";
import Settings from "./pages/Settings";
import ManagePolls from "./pages/ManagePolls";
import CreatePoll from "./pages/CreatePoll";
import PromoCode from "./pages/promoCode";
import Sponsor from "./pages/Sponsor";
// import Members from "./pages/Members";
import EventsDetail from "./pages/EventsDetail";
import Notification from "./pages/Notification";
import StudentQueries from "./pages/StudentQueries";
import Emails from "./pages/email/Emails";
import Subscription from "./pages/Subscription";
import VerifyOTP from "./pages/auth/VerifyOTP";
import ResetPassword from "./pages/auth/ResetPassword";
import AllCourses from "./pages/courses/AllCourses";
import AddEditCourse from "./pages/courses/AddEditCourse";
import CheckIfLoggedIn from "./components/Auth/CheckIfLoggedIn";
import Interest from "./pages/interest/Interest";
import ScheduledEvents from "./pages/events/ScheduledEvents";
import FeaturedEvents from "./pages/events/FeaturedEvents";
import EventDetail from "./pages/events/EventDetail";
import EventResources from "./pages/events/DetailPages/EventResources";
import ForumDetail from "./pages/events/DetailPages/ForumDetailPage";
import Members from "./pages/members/Members";
import Attendee from "./pages/members/Attendee";
import Subadmins from "./pages/subadmin/Subadmin";
import PastEvents from "./pages/events/PastEvents";
import MemberRequest from "./pages/members/MemberRequest";
import CreateEvent from "./pages/events/CreateEvent";
import AddEditCompany from "./pages/company/AddEditCompany";
import AllEvents from "./pages/events/AllEvents";
import CourseSales from "./pages/sales/CourseSales";
import TicketSales from "./pages/sales/TicketSales";
import PopularCourses from "./pages/courses/PopularCourses";
import EnrolledMember from "./pages/courses/EnrolledMembers";
import SponsorsListing from "./pages/sponsors/SponsorsListing";
import AddEditSponsor from "./pages/sponsors/AddEditSponsor";
import UploadedImages from "./pages/images/UploadedImages";
import TicketSalesDetail from "./pages/sales/TicketSalesDetail";
import CourseSalesDetail from "./pages/sales/CourseSalesDetail";
import TicketSessionDetail from "./pages/sales/TicketSessionDetail";
import PostDetails from "./pages/events/DetailPages/PostDetails";
import ForumDetailPage from "./pages/events/DetailPages/ForumDetailPage";
import ForumList from "./pages/ForumList";
import ReportedPost from "./pages/ReportedPost";


function App() {
	return (
		<Routes>
			<Route path={"/"} element={<CheckIfLoggedIn />} />
			<Route element={<Signup />} path="/signup"></Route>
			<Route element={<Login />} path="/login"></Route>
			<Route element={<ForgotPassword />} path="/forgotPassword"></Route>
			<Route path="/verifyOTP" element={<VerifyOTP />} />
			<Route path="/resetPassword" element={<ResetPassword />} />
			<Route element={<CheckIfLoggedIn />}>
				<Route element={<DashboardLayout />}>
					<Route element={<Dashboard />} path="/home"></Route>

					<Route element={<Companies />} path="/companies"></Route>
					<Route element={<AddEditCompany />} path="/companies/add" />
					<Route
						element={<AddEditCompany />}
						path="/companies/edit/:companyId"
					/>

					<Route element={<Courses />} path="/courses" />
					<Route element={<AllCourses />} path="/allCourses" />
					<Route element={<PopularCourses />} path="/popular-courses" />
					<Route element={<AddEditCourse />} path="/courses/add" />
					<Route element={<AddEditCourse />} path="/courses/edit/:courseId" />
					<Route
						element={<EnrolledMember />}
						path="/courses/enrolled-members"
					/>

					<Route element={<ScheduledEventss />} path="/scheduledEvents"></Route>
					<Route element={<Settings />} path="/settings"></Route>
					<Route element={<ManagePolls />} path="/manage-polls"></Route>
					<Route element={<CreatePoll />} path="/create-poll"></Route>
					<Route element={<PromoCode />} path="/promoCodes"></Route>
					<Route element={<Sponsor />} path="/payment/sponsor"></Route>
					{/* <Route element={<Members />} path="/members"></Route> */}
					<Route element={<EventsDetail />} path="/event-detail"></Route>
					<Route element={<Notification />} path="/notifications"></Route>
					<Route
						element={<StudentQueries />}
						path="/courses/student-queries"
					></Route>
					<Route element={<Emails />} path="/emails"></Route>
					<Route
						element={<Subscription />}
						path="/payment/subscription"
					></Route>

					<Route element={<Interest />} path="/interest" />

					<Route
						path="/events/scheduled-events"
						element={<ScheduledEvents />}
					/>

					{/* <Route path="/events/all-events" element={<AllEvents />} /> */}
					<Route path="/events/featured-events" element={<FeaturedEvents />} />
					<Route path="/events/past-events" element={<PastEvents />} />

					<Route path="/events/detail/:eventId" element={<EventDetail />} />
					{/* <Route path="/Forums/detail/:eventId" element={<ForumDetail />} /> */}
					<Route path="/forums/detail/:forumId" element={<ForumDetailPage />} />
					<Route path="/forum-list" element={<ForumList />} />
					<Route path="/Reported_Post" element={<ReportedPost />} />


					<Route
						path="/events/detail/resources/:eventId/:tab"
						element={<EventResources />}
					/>
          <Route
            path="/events/detail/resources/:eventId/:tab/:postId/edit"
            element={<PostDetails />}
          />
					<Route path="/events/create" element={<CreateEvent />} />
					<Route path="/events/edit/:eventId" element={<CreateEvent />} />

					<Route element={<Subadmins />} path="/subadmins" />

					<Route element={<Members />} path="/members" />

					<Route element={<Attendee />} path="/members/attendee" />

					<Route element={<MemberRequest />} path="/user-requests" />

					<Route element={<CourseSales />} path="/sales/courses" />
					<Route element={<TicketSales />} path="/sales/tickets" />
					<Route
						element={<TicketSalesDetail />}
						path="/sales/tickets/detail/:eventId"
					/>
					<Route
						element={<CourseSalesDetail />}
						path="/sales/courses/detail/:courseId"
					/>

					<Route
						element={<TicketSessionDetail />}
						path="/sales/tickets/session/detail/:ticketId"
					/>

					<Route element={<SponsorsListing />} path="/sponsors" />
					<Route element={<AddEditSponsor />} path="/sponsors/add" />
					<Route
						element={<AddEditSponsor />}
						path="/sponsors/edit/:sponsorId"
					/>

					<Route element={<UploadedImages />} path="/images" />
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
