import React, { useState } from "react";
import Listing from "../../components/ui/Listing";
import { ADMIN_EVENT_SALES_DETAIL } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { Box, Button, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import Loader from "../../components/overlay/Loader";
import GoBackButton from "../../components/Buttons/GoBackButton";
import CustomPaginations from "../../components/ui/CustomPaginations";

const styles = {
	button: {
		bgcolor: "#fff",
		color: "#000",
		textTransform: "capitalize",
		border: "1px solid #000",
		"&:hover": {
			bgcolor: "#fff",
			color: "#000",
		},
	},
};

const TicketSalesDetail = () => {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		limit: 10,
	});
	const [eventDetail, setEventDetail] = useState(null);

	const { eventId } = useParams();
	const isId = eventId !== null && typeof eventId !== "undefined";

	const navigate = useNavigate();

	const columns = [
		{
			field: "purchased_by",
			headerName: "Purchased By",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						component="div"
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.purchased_by}
					</Box>
				);
			},
		},
		{
			field: "ticket_name",
			headerName: "Ticket Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "transaction_amount",
			headerName: "Transaction Amount",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.transaction_amount}` || "-",
		},
		{
			field: "total_seats_booked",
			headerName: "Total Seats Booked",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.total_seats_booked || "-",
		},
		{
			field: "purchase_date",
			headerName: "Purchase Date",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.purchase_date || "-",
		},
		{
			headerName: "Action",
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<Button
					variant="contained"
					sx={styles.button}
					onClick={() =>
						navigate(`/sales/tickets/session/detail/${params.row.id}`)
					}
				>
					View Detail
				</Button>
			),
		},
	];

	const fetchEventDetail = async () => {
		const url = new URL(`${ADMIN_EVENT_SALES_DETAIL}/${eventId}`);

		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.limit);

		const { status, message, data } = await get(url.toString());
		if (status === true) {
			setEventDetail(data);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const { isLoading, isFetching } = useQuery(
		[queryNames.EVENTS, eventId, paginationModel.page],
		fetchEventDetail,
		{
			enabled: isId,
			keepPreviousData: true,
		}
	);

	const handleCellClick = (data) => {
		if (data?.field === "purchased_by") {
			navigate(`/sales/tickets/session/detail/${data.id}`);
		}
	};

	if (isLoading || isFetching) {
		return <Loader />;
	}

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<PageHeader
					title={`${eventDetail?.event_name} - Sales Details`}
					// placeholder="Search Events"
					// searchInput={true}
				/>

				<GoBackButton url={-1} />
			</Stack>

			<Listing
				columns={columns}
				type="courses"
				rows={eventDetail?.purchase_tickets}
				mode="local"
				handleCellClick={handleCellClick}
			/>
			<CustomPaginations
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				totalCount={eventDetail?.totalCount}
			/>
		</>
	);
};

export default TicketSalesDetail;
