import React, { useState } from "react";
import {
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { Close } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  ADMIN_GET_POST_BY_ID,
  ADMIN_GET_POST_COMMENTS,
  ADMIN_ADD_POST_COMMENT,
  ADMIN_DELETE_POST_COMMENT,
} from "../../../data/constants/apiRoutes";
import { get, post, destroy } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import Listing from "../../../components/ui/Listing";
import PageHeader from "../../../components/ui/PageHeader";
import { validator } from "../../../utils/helpers/validator";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";
import styles from "../../../assets/styles/events/eventDetails.styles";
import PostCommentSchema from "../../../utils/schemas/PostCommentSchema";
import BoxModal from "../../../components/Modals/BoxModal";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import FormInput from "../../../components/Forms/FormInput";
import GoBackButton from "../../../components/Buttons/GoBackButton";

//SVG
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";

//STYLES
const styless = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& h3": {
      fontSize: 16,
      fontWeight: 600,
      color: "#000",
      mb: 1,
    },
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
};

//INITIAL STATE
const initialState = {
  description: "",
};

export default function PostDetails() {
  const { postId } = useParams();
  const client = useQueryClient();

  //USESTATE
  const [newComment, setNewComment] = useState(initialState);
  const [postData, setPostData] = useState({});
  const [commentId, setCommentId] = useState(null);
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  //COLUMN
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.description,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 180,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "favCount",
      headerName: "Favourite Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.favCount,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setCommentId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //GET DATA OF POST
  const fetchPostDetail = async () => {
    const { status, data, message } = await get(
      `${ADMIN_GET_POST_BY_ID}/${postId}`
    );
    if (status === true) {
      setPostData(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const postDetailQuery = useQuery([queryNames.POSTS], fetchPostDetail, {
    keepPreviousData: true,
  });

  //ANY CHANGE IN FORM HANDLED BY THIS METHOD
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setNewComment({ ...newComment, [name]: value });
  };

  //FORM VALIDATION
  const validateForm = () => {
    const newErrors = validator(newComment, PostCommentSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  //MODAL OPENING AND CLOSING

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleClose = () => {
    setNewComment(initialState);
    setCommentId(null);
    setModal(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setCommentId(null);
  };

  //DATA UPDATING AFTER API IS CALLED
  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.POSTS);
  };

  //CALLING METHOD TO GET COMMENTS OF POST
  const fetchPosts = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_POST_COMMENTS}/${postId}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //API METHODS
  const saveComment = async () => {
    const payload = _.cloneDeep(newComment);
    const { status, message } = await post(
      `${ADMIN_ADD_POST_COMMENT}/${postId}`,
      {
        description: payload.description,
      }
    );
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const deleteComment = async () => {
    const { status, message } = await destroy(
      `${ADMIN_DELETE_POST_COMMENT}/${commentId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //MUTATION AND ITS METHOD
  const mutation = useMutation(saveComment);
  const mutationDelete = useMutation(deleteComment);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Post Details" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
          <PageHeader
            createBtn
            createBtnText="Add Comment"
            btnFnc={handleOpenModal}
          />
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <LoadingOverlay
          loading={postDetailQuery.isLoading || postDetailQuery.isFetching}
        />
        <Box sx={{ ...styles.wrapper, mb: 0 }}>
        <Stack direction="row" alignItems="center">
          <Avatar
            src={postData?.owner?.profile_image}
            sx={{
              bgcolor: "gray",
              mt: 2,
              ml: 1.5,
              mr: 1.5,
              width: 35,
              height: 35,
            }}
          >
            {postData?.owner?.full_name.charAt(0).toUpperCase()}
          </Avatar>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              {postData?.owner?.full_name}
            </Typography>
            <Typography variant="body2">
              {(() => {
                const createdAt = moment(postData?.created_at);
                const currentTime = moment();
                const timeDifference = moment.duration(
                  currentTime.diff(createdAt)
                );

                if (timeDifference.days() > 5) {
                  return moment(postData?.created_at).format(
                    "D-MMM-YYYY | h:mm A"
                  );
                } else {
                  let formattedTime = "";

                  if (timeDifference.days() > 0) {
                    formattedTime += `${timeDifference.days()}d `;
                  }

                  if (
                    timeDifference.hours() > 0 ||
                    timeDifference.days() > 0
                  ) {
                    formattedTime += `${timeDifference.hours()}h `;
                  }

                  formattedTime += `${timeDifference.minutes()}m ago`;

                  return formattedTime;
                }
              })()}
              {/* {moment(postData?.created_at).format(
                "D-MMM-YYYY | h:mm A"
              )} */}
              {/* {(() => {
                const createdAt = moment(postData?.created_at);
                const currentTime = moment();
                const timeDifference = moment.duration(
                  currentTime.diff(createdAt)
                );
                return `${timeDifference.days()}d ${timeDifference.hours()}h ${timeDifference.minutes()}m ago`;
              })()} */}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ mt: 2, ml: 5 }}>
          <Typography variant="body2">
            {postData?.description}
          </Typography>
        </Box>

        {postData?.assets?.length ? (
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            adaptiveHeight={true}
          >
            {postData?.assets?.map((asset, index) => (
                <Grid container>
                  <Grid md={12} item sx={styles.leftWrapper}>
                    <Box>
                      {
                        <Box
                          key={index} // Add a unique key for each image
                          component={asset.asset_type == "image" ? "img" : "iframe" }
                          src={asset.asset_url}
                          loading="lazy"
                          sx={{
                            maxWidth: "100%",
                            maxHeight: 500,
                            margin: "0 auto",
                            borderRadius: 3,
                          }}
                        />
                      }
                    </Box>
                  </Grid>
                </Grid>

            ))}
          </Slider>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
              minHeight: "10vh",
            }}
          >
            <Typography variant="h6">Post has no Assets</Typography>
          </Box>
        )}
        </Box>
      </Box>

      <Listing
        columns={columns}
        queryName={queryNames.POSTS}
        fetchRecords={fetchPosts}
        className="posts"
      />

      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styless.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2">Add new Comment</Typography>
          <Grid container spacing={3} mb={2}>
            <Grid item md={12} sm={12}>
              <FormInput
                label="Description"
                placeholder="Description"
                value={newComment.description}
                name="description"
                onChange={handleChange}
                multiline
                rows={4}
                error={errors?.description}
                helperText={errors?.description || ""}
              />
            </Grid>
          </Grid>

          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
            sx={styless.button}
          >
            Submit
          </LoadingButton>
        </Box>
      </Drawer>

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this comment"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
}
