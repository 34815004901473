import {
  Grid,
  Typography,
  Stack,
  Box,
  Button,
  Avatar,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

import BannerImg from "../../assets/images/EventsBanner.jpg";
import { ReactComponent as Documents } from "../../assets/svg/Document.svg";
import { ReactComponent as Photos } from "../../assets/svg/Photos.svg";
import { ReactComponent as Sponsors } from "../../assets/svg/Sponsors.svg";
import { ReactComponent as Speakers } from "../../assets/svg/Speakers.svg";
import { ReactComponent as Agenda } from "../../assets/svg/Agenda.svg";
import CompanyLogo from "../../assets/images/CompanyLogo.png";
import ReservationChart from "../../components/pageComponents/EventDetails/ReservationChart";
import SalesReport from "../../components/pageComponents/EventDetails/SalesReport";
import Directions from "../../components/pageComponents/EventDetails/Directions";
import { useNavigate, useParams } from "react-router-dom";
import queryNames from "../../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { get } from "../../server";
import {
  ADMIN_EVENT_DETAIL,
  ADMIN_EVENT_FEATURE,
} from "../../data/constants/apiRoutes";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import moment from "moment";
import styles from "../../assets/styles/events/eventDetails.styles";
import Loader from "../../components/overlay/Loader";
import LoadingButton from "../../components/Buttons/LoadingButton";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text?.slice(0, 250) : text}
      {!isReadMore && (
        <div onClick={toggleReadMore} className="read">
          {isReadMore ? "Read more" : " Read less"}
        </div>
      )}
    </>
  );
};

function EventDetail() {
  const [eventDetail, setEventDetail] = useState();

  const { eventId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const isId = eventId !== null && typeof eventId !== "undefined";

  const fetchEventDetail = async () => {
    const { status, message, data } = await get(
      `${ADMIN_EVENT_DETAIL}/${eventId}`
    );
    if (status === true) {
      setEventDetail(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const { isLoading, isFetching } = useQuery(
    [queryNames.EVENTS, eventId],
    fetchEventDetail,
    {
      enabled: isId,
      keepPreviousData: true,
    }
  );

  const featureEvent = async () => {
    const { status, message } = await get(`${ADMIN_EVENT_FEATURE}/${eventId}`);
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      // navigate("/events/scheduled-events");
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(featureEvent);

  const handleFeature = () => {
    mutation.mutate();
  };

  const resourceUrl = `/events/detail/resources/${eventId}`;

  if (isLoading || isFetching) return <Loader />;

  return (
    <Box sx={styles}>
      <Typography sx={styles.title}>Event Detail</Typography>
      <LoadingOverlay loading={isLoading} />

      <Box sx={styles.wrapper}>
        <Grid container>
          <Grid md={8} item sx={styles.leftWrapper}>
            <Box sx={styles.banner}>
              <Box
                component="img"
                src={
                  eventDetail?.event.cover_image
                    ? eventDetail?.event.cover_image
                    : BannerImg
                }
                loading="lazy"
              />
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={styles.header}
            >
              <Stack direction="row" columnGap={1.5} alignItems="center">
                <Box
                  component="img"
                  src={eventDetail?.event?.company?.company_logo}
                  loading="lazy"
                />
                <Box>
                  <Typography component="p">Created By</Typography>
                  <Typography component="h3">
                    {eventDetail?.event?.company?.company_name}
                  </Typography>
                </Box>
              </Stack>
              {eventDetail?.event?.is_featured ? (
                // <Stack sx={styles.bannerBtn}>
                <Box component="label" sx={styles.eventType}>
                  Featured
                </Box>
              ) : (
                // </Stack>
                <LoadingButton
                  variant="contained"
                  onClick={handleFeature}
                  isLoading={mutation.isLoading}
                  sx={styles.featureBtn}
                >
                  Feature This Event
                </LoadingButton>
              )}
            </Stack>

            <Box sx={styles.detail}>
              <Typography component="h2">
                {eventDetail?.event?.event_name}
              </Typography>
              <Typography component="h6">
                {moment(eventDetail?.event?.start_time).format(
                  "D-MMM-YYYY | h:mm A"
                )}{" "}
                {eventDetail?.event?.end_time &&
                  ` - ${moment(eventDetail?.event?.end_time).format(
                    "D-MMM-YYYY | h:mm A"
                  )}`}
              </Typography>

              {eventDetail?.event?.members?.length > 0 && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <AvatarGroup max={4}>
                    {eventDetail?.event?.members?.map((member, index) => (
                      <Tooltip title={member.event_member.full_name}>
                        <Avatar
                          alt={member.event_member.full_name}
                          src={member.event_member.profile_image}
                        />
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                </Stack>
              )}
            </Box>
            <Box sx={styles.additional}>
              <Typography component="h2">Additional Resources</Typography>
              <Box sx={styles.btnGrid}>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Documents />}
                  onClick={() => navigate(`${resourceUrl}/document`)}
                >
                  Document
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Photos />}
                  onClick={() => navigate(`${resourceUrl}/photo`)}
                >
                  Photos
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Sponsors />}
                  onClick={() => navigate(`${resourceUrl}/sponsor`)}
                >
                  Sponsors
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Speakers />}
                  onClick={() => navigate(`${resourceUrl}/speaker`)}
                >
                  Speakers
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Agenda />}
                  onClick={() => navigate(`${resourceUrl}/agenda`)}
                >
                  Agenda
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Documents />}
                  onClick={() => navigate(`${resourceUrl}/forums`)}
                >
                  Forums
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableTouchRipple
                  startIcon={<Agenda />}
                  onClick={() => navigate(`${resourceUrl}/posts`)}
                >
                  Posts
                </Button>
                {/* <Button
									variant="contained"
									disableElevation
									disableTouchRipple
									startIcon={<Speakers />}
									onClick={() => navigate(`${resourceUrl}/guest-speaker`)}
								>
									Guest Speakers
								</Button> */}
              </Box>
              <Typography component="h2">Event Details</Typography>
              <Typography component="p">
                <ReadMore>{eventDetail?.event?.description}</ReadMore>
              </Typography>
              {eventDetail?.event?.hotel_description &&
                eventDetail?.event?.hotel_reservation_end_date && (
                  <>
                    <Typography component="h2">Hotel Reservation</Typography>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Grid container spacing={0}>
                          <Grid item md={3}>
                            <Typography
                              variant="h6"
                              sx={{ color: "#767D90 !important", fontSize: 16 }}
                            >
                              Hotel Name:
                            </Typography>
                          </Grid>

                          <Grid item md={9}>
                            <Typography variant="h6">
                              {eventDetail?.event?.hotel_description}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item md={12}>
                          <Grid container spacing={0}>
                            <Grid item md={3}>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "#767D90 !important",
                                  fontSize: 16,
                                }}
                              >
                                Check in:
                              </Typography>
                            </Grid>

                            <Grid item md={9}>
                              <Typography variant="h6">
                                {eventDetail?.event?.hotel_reservation_end_date}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={styles.reservationBtn}
                      onClick={() =>
                        window.open(
                          eventDetail?.event?.hotel_reservation_url,
                          "_blank"
                        )
                      }
                    >
                      Check Reservation
                    </Button>
                  </>
                )}
            </Box>
          </Grid>
          <Grid item md={4}>
            {/* <ReservationChart />
						<SalesReport /> */}
            <Directions event={eventDetail?.event} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default EventDetail;
