const styles = {
    header: {
        fontSize: 22,
        fontWeight: 600,
        color: "#1E1E1F",
        pb: 2.5,
    },
    title: {
        pb: 2.5,
        flexWrap: "wrap",
        "& h1": {
            fontSize: 20,
            fontWeight: 600,
            color: "#1E1E1F",
            flex: 1,
        },
        "& button": {
            minWidth: 150,
            bgcolor: "#2B2A69",
            color: "#FFF",
            fontWeight: 600,
            textTransform: "capitalize",
            px: 3,
            "&:hover": {
                bgcolor: "#2B2A69",
                color: "#FFF",
            },
        },
        "& h4": {
            color: "#1E1E1F",
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "36px",
            mb: 1,
        },
    },
    container: {
        bgcolor: "#FFF",
        borderRadius: "12px",
        py: 4,
        px: "30px",
        mt: "15px"
    },
    attachmentContainer: {
        bgcolor: "rgba(226, 227, 228, 0.20)",
        borderRadius: "4px",
        border: "1px dashed var(--light-background-color-2, #E2E3E4)",
        fontSize: 18,
        fontWeight: 500,
        color: "#767D90",
        py: "12px",
        px: "18px",
        width: 50,
        height: 54,
        cursor: "pointer",
    },
    documentContainer: {
        bgcolor: "#FAFAFF",
        borderRadius: "4px",
        py: "10px",
        px: "10px",
        position: "relative",
        alignItems: "center",
        maxWidth: 160,
        "& h6": {
            color: "#1E1E1F",
            fontSize: 12,
            fontWeight: 500,
            lineHeight: "20px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: 110,
            "@media (max-width: 1250px)": {
                maxWidth: 65,
            },
            "@media (max-width: 1050px)": {
                maxWidth: 45,
            },
            "@media (max-width: 890px)": {
                maxWidth: 100,
            },
        },
        "& label": {
            color: "#767D90",
            fontSize: 10,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: 110,
            "@media (max-width: 1050px)": {
                fontSize: 8,
                maxWidth: 45,
            },
            "@media (max-width: 615px)": {
                fontSize: 8,
                maxWidth: 45,
            },
            "@media (max-width: 890px)": {
                maxWidth: 100,
            },
        },
    },
    addPhotoContainer: {
        bgcolor: "rgba(226, 227, 228, 0.20)",
        borderRadius: "4px",
        border: "1px dashed var(--light-background-color-2, #E2E3E4)",
        fontSize: 18,
        fontWeight: 500,
        color: "#767D90",
        width: 100,
        height: 100,
        cursor: "pointer",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageContainer: {
        height: 100,
        width: 100,
        borderRadius: '4px',
        position: 'relative',
        objectFit: "cover",
        border: "1px solid #eee"
    },
    removeIcon: {
        position: "absolute",
        right: -7,
        top: -7,
        cursor: "pointer",
    },
    photoRemoveIcon: {
        position: "absolute",
        width: 20,
        right: -4,
        top: -4,
        cursor: "pointer",
    },
    divider: {
        color: "#EEE",
        my: 2,
    },
    datePicker: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F9F9F9",
            borderRadius: "6px",
            "& fieldset": {
                border: "1px solid #EEE",
            },
            "&:hover fieldset": {
                borderColor: "#656DFC",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#656DFC",
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #E2E3E4",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2B2A69 !important",
            borderWidth: "1px !important",
        },

        "& input": {
            "&::placeholder": {
                color: "#767D90",
                fontWeight: 400,
                opacity: 1,
                fontSize: 14,
            },
        },
        "& p": {
            fontSize: 14
        }
    },
    eventImageContainer: {
        borderRadius: "4px",
        border: "1px dashed var(--light-background-color-2, #E2E3E4)",
        bgcolor: "#FBFCFF",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        height: 200,
        "& button": {
            borderRadius: "4px",
            border: "1px solid #2B2A69",
            bgcolor: "#FFF",
            color: "#2B2A69",
            fontWeight: 500,
            textTransform: "capitalize",
            "&:hover": {
                bgcolor: "#FFF",
                border: "1px solid #2B2A69",
            }
        },
        "& label": {
            color: "#767D90",
            fontSize: 14,
        }
    },
    coverImageContainer: {
        borderRadius: "4px",
        border: "1px solid #eee",
        height: 200,
        width: "100%",
        objectFit: "cover",
    },
    coverHover: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        gap: '10px',
        transition: "display 0.3s ease",
        "& button": {
            borderRadius: "4px",
            border: "1px solid #2B2A69",
            bgcolor: "#FFF",
            color: "#2B2A69",
            fontWeight: 500,
            textTransform: "capitalize",
            "&:hover": {
                bgcolor: "#FFF",
                border: "1px solid #2B2A69",
            }
        },
        "& label": {
            color: "#767D90",
            fontSize: 14,
        }
    },
    scrollButtonStyles: {
        position: "fixed",
        right: 25,
        bottom: 25,
        width: 36,
        height: 36,
        boxShadow: "none",
        backgroundColor: "#2B2A69",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2B2A69",
        },
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
    },
    visibleScrollButtonStyles: {
        opacity: 1,
    },

    memberFullStack: {
        borderBottom: "1px solid #E2E3E4",
        '&:last-child': {
            borderBottom: 'none', // Remove border for the last item
        },
        px: 1.5,
    },
    memberStack: {
        alignItems: "center",
        gap: 3,
        py: "12px",
    },
    memberAvatar: {
        width: 60,
        height: 60,
        bgcolor: "gray",
        textTransform:"capitalize"
    },
    memberNameStack: {
        flex: 1,
        "& h4": {
            fontSize: 17,
            fontWeight: 500,
            color: "#1E1E1F",
            lineHeight: "20px"
        },
        "& label": {
            fontSize: 14,
            color: "#767D90",
        },
    },
    memberCheckbox: {
        "& svg": {
            width: 30,
            height: 30
        }
    },
    sessionToLabel: {
        color: "#767D90",
        fontSize: 16
    },
    continueBtn: {
        width: 150,
        bgcolor: "#2B2A69",
        color: "#FFF",
        fontWeight: 600,
        textTransform: "capitalize",
        px: 3,
        "&:hover": {
            bgcolor: "#2B2A69",
            color: "#FFF",
        },
    },
    added: {
        color: "#0F4590",
        fontSize: 16,
        fontWeight: 600,
        mt: 1,
        cursor: "pointer",
    },
    removeBtn: {
        width: 40,
        height: 40,
        color: "#AC3049",
    },
    agendaContainer: {
        alignItems: "center",
        gap: 2,
        pb: 3,
        mb: 3,
        borderBottom: "1px solid #E2E3E4",
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    label: {
        "& .MuiTypography-root": {
            color: "#767D90",
            fontWeight: 400,
            fontSize: 16,
            display: "block",
        }
    },
    totalSessions: {
        color: "#767D90",
        fontWeight: 400,
        fontSize: 16,
        display: "block",
        my: 2,
    },
    ticketTitle: {
        fontSize: 16,
        fontWeight: 600,
        color: "#1E1E1F",
        mb: "7px"
    },
    switch: {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#2B2A69', // Active color
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#2B2A69', // Active track color
        },
    },
    accordion: {
        bgcolor: "#FBFBFB",
        borderRadius: "4px",
        border: "none",
        boxShadow: "none",
        "&:before": {
            bgcolor: "#fff"
        },
        mb: 2,
        // MuiAccordion-root
        // "& .MuiPaper-root": {
        // }
    },
    accordianSummary: {
        width: "33%",
        flexShrink: 0,
        color: "#1E1E1F",
        fontSize: 16,
    },
    accordianDetail: {
        color: "#767D90",
        fontSize: 13,
    },
    Previewheader: {
        fontSize: 20,
        fontWeight: 600,
        color: "#1E1E1F",
        mb: "10px"
    },
    banner: {
        width: "100%",
        height: 200,
        objectFit: "cover",
        borderRadius: 2.5,
    },
    detail: {
        mt: 1,
        "& h2": {
            color: "#1E1E1F",
            fontWeight: 600,
            fontSize: 20,
        },
        "& svg": {
            width: 18,
            height: 20
        }
    },
    eventPrice: {
        color: "#2B2A69",
        fontWeight: 600,
        fontSize: 20,
    },
    eventType: {
        color: "#767D90",
        fontWeight: 600,
        fontSize: 14,
    },
    totalMembers: {
        color: "#767D90",
        fontWeight: 600,
        fontSize: 14,
    },
    headerValue: {
        color: "#1E1E1F",
        fontSize: 17,
    },
    faqHeader: {
        color: "#1E1E1F",
        fontWeight: 600,
        fontSize: 20,
        mb: 2,
    },
    goBackBtn: {
        width: 150,
        bgcolor: "#2B2A69",
        color: "#FFF",
        fontWeight: 600,
        textTransform: "capitalize",
        px: 3,
        "&:hover": {
            bgcolor: "#2B2A69",
            color: "#FFF",
        },
    },
    addEmail: {
        bgcolor: "#2B2A69",
        color: "#FFF",
        fontWeight: 600,
        textTransform: "capitalize",
        px: 3,
        "&:hover": {
            bgcolor: "#2B2A69",
            color: "#FFF",
        },
    },
    dividerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: 2,
        gap: 4,
        "& span": {
            color: "#767D90",
            fontSize: 16,
        }
    },
    divider2: {
        flex: 1,
        color: "#EEE",
    },
    avatarWrapper: {
        columnGap: { md: 3, xs: 2 },
    },
    avatar: {
        height: { md: 110, xs: 80 },
        width: { md: 110, xs: 80 },
    },
    btnWrapper: {
        flexDirection: { md: "column", xs: "row" },
        gap: { md: 2, xs: 1.5 },
    },
    editBtn: {
        bgcolor: "#e2d7e5",
        color: "#2B2A69",
        py: 1.5,
        px: 3,
        fontSize: { md: 12, xs: 10 },
        fontWeight: 600,
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        maxWidth: { md: "100%", xs: 100 },
    },
    deleteBtn: {
        bgcolor: "#ffeeee",
        color: "#FF5757",
        py: 1.5,
        fontSize: { md: 12, xs: 10 },
        px: 3,
        fontWeight: 600,
        textTransform: "capitalize",
        maxWidth: { md: "100%", xs: 100 },
        whiteSpace: "nowrap",
    },
    guestSpeakerTitle: {
        fontSize: 30,
        mb: 2
    },
    btn: {
		display: "flex",
		alignItems: "center",
		mr: 2,
		width: 130,
		bgcolor: "#FFF",
		color: "#2B2A69",
		fontWeight: 600,
		textTransform: "capitalize",
		border: "1px solid #2B2A69",
		px: 2,
		"&:hover": {
			bgcolor: "#FFF",
			color: "#2B2A69",
		},
	},
};

export default styles