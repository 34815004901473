export const uploadImageType = {
  editProfile: "EditProfile",
  createEvent: "CreateEvent",
  comapnyProfile: "CompanyProfile",
  sponsorProfile: "SponsorProfile",
  createCourse: "CreateCourse",
  createSponsor: "CreateSponsor",
  createSpeaker: "CreateSpeaker",
  createPost: "CreatePost",
};
