import React, { useState } from "react";
import Listing from "../../components/ui/Listing";
import { ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import Loader from "../../components/overlay/Loader";
import GoBackButton from "../../components/Buttons/GoBackButton";

const TicketSessionDetail = () => {
	const [session, setSession] = useState(null);

	const { ticketId } = useParams();
	const isId = ticketId !== null && typeof ticketId !== "undefined";

	const columns = [
		{
			field: "ticket_type",
			headerName: "Ticket Type",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "session_type",
			headerName: "Session Type",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "quantity",
			headerName: "Quantity",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.quantity || "-",
		},
	];

	const fetchTicketDetail = async (pageNo, limit) => {
        let url = new URL(`${ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL}/${ticketId}`);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	}

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<PageHeader
					title={`Session Details`}
					// placeholder="Search Events"
					// searchInput={true}
				/>

				<GoBackButton url={-1} />
			</Stack>

			<Listing
				columns={columns}
				type="courses"
				fetchRecords={fetchTicketDetail}
                queryName={queryNames.TICKET_SESSION}
			/>
		</>
	);
};

export default TicketSessionDetail;
