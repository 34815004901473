import React from "react";
import Listing from "../../components/ui/Listing";
import { ADMIN_COURSES_ENROLLED_MEMBERS } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";

const EnrolledMembers = () => {
	const columns = [
		{
			field: "full_name",
			headerName: "Full Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "email",
			headerName: "Email",
			sortable: false,
			width: 200,
		},
		{
			field: "mobile_number",
			headerName: "Mobile Number",
			sortable: false,
			width: 150,
			valueGetter: (params) => params.row.mobile_number || "-",
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex:1,
			valueGetter: (params) => params.row.address || "-",
		},
		
	];

	const fetchCourses = async (pageNo, limit) => {
		let url = new URL(ADMIN_COURSES_ENROLLED_MEMBERS);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	return (
		<>
			<PageHeader title="Enrolled Members" />

			<Listing
				columns={columns}
				queryName={queryNames.COURSES}
				fetchRecords={fetchCourses}
				type="courses"
			/>
		</>
	);
};

export default EnrolledMembers;
