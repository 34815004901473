import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../assets/styles/events/createEvent.styles";
import EventTabs from "../../../components/Tabs/EventTabs";
import MemberTab from "./MemberTabs/MemberTab";
import SpeakerTab from "./MemberTabs/SpeakerTab";
import SponsorTab from "./MemberTabs/SponsorTab";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { get } from "../../../server";
import {
	ADMIN_COMPANY_MEMBERS_LISTING,
	ADMIN_COMPANY_SPONSORS_LISTING,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import GuestSpeakerTab from "./MemberTabs/GuestSpeakerTab";

const EventMembrs = ({
	currentStep,
	setCurrentStep,
	eventForm,
	setEventForm,
	handleGoBack,
}) => {
	const [members, setMembers] = useState([]);
	const [speakers, setSpeakers] = useState([]);
	const [sponsors, setSponsors] = useState([]);

	const { enqueueSnackbar } = useSnackbar();

	const fetchMembers = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COMPANY_MEMBERS_LISTING}/${eventForm.details.company_id}`
		);
		if (status === true) {
			const newData = data.map((member) => ({
				id: member.id,
				full_name: member.full_name,
				profile_image: member.profile_image,
				email: member.email,
			}));
			if (eventForm.members.members_email.length) {
				const newMembers = eventForm.members.members_email.map((member) => ({
					full_name: member,
					email: member,
				}));
				newData.push(...newMembers);
			}
			if (eventForm?.members?.members?.length) {
				const newMems = eventForm?.members?.members?.filter(
					(member) => !newData.some((speaker) => speaker.email === member.email)
				);
				newData.push(...newMems);
			}
			setMembers((prev) => [...prev, ...newData]);
			const newSpeakers = eventForm.members.speakers.filter(
				(member) => !newData.some((speaker) => speaker.email === member.email)
			);
			setSpeakers((prev) => [...prev, ...newData, ...newSpeakers]);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const { isLoading } = useQuery(queryNames.MEMBERS, fetchMembers, {
		refetchOnWindowFocus: false,
	});

	const fetchSponsors = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COMPANY_SPONSORS_LISTING}/${eventForm.details.company_id}`
		);
		if (status === true) {
			let newData = data.map((sponsor) => ({
				...sponsor,
				id: sponsor.id,
				label: sponsor.company_name,
			}));
			newData.push({ id: null, label: "Others.." });
			setSponsors(newData);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};
	const { isLoading: newLoading } = useQuery(
		queryNames.SPONSOR,
		fetchSponsors,
		{
			refetchOnWindowFocus: false,
		}
	);

	const tabList = [
		{
			label: "Add Members",
			component: (
				<MemberTab
					members={members}
					setMembers={setMembers}
					loading={isLoading}
					eventForm={eventForm}
					setEventForm={setEventForm}
				/>
			),
		},
		{
			label: "Add Speakers",
			component: (
				<SpeakerTab
					speakers={speakers}
					setSpeakers={setSpeakers}
					loading={isLoading}
					eventForm={eventForm}
					setEventForm={setEventForm}
				/>
			),
		},
		// {
		// 	label: "Add Guest Speakers",
		// 	component: (
		// 		<GuestSpeakerTab
		// 			loading={isLoading}
		// 			eventForm={eventForm}
		// 			setEventForm={setEventForm}
		// 		/>
		// 	),
		// },
		{
			label: "Add Sponsors",
			component: !newLoading && (
				<SponsorTab
					sponsors={sponsors}
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					loading={newLoading}
				/>
			),
		},
	];

	return (
		<>
			<Box sx={styles.container}>
				<Box textAlign="end">
					<Button
						variant="contained"
						sx={styles.goBackBtn}
						onClick={handleGoBack}
					>
						Go Back
					</Button>
				</Box>
				<EventTabs tabList={tabList} />
			</Box>
		</>
	);
};

export default EventMembrs;
