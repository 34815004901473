import { Avatar, Typography, Box } from "@mui/material";
import React from "react";
import Listing from "../../ui/Listing";

const styles = {
	header: {
		bgcolor: "#fff",
		borderTopLeftRadius: "12px",
		borderTopRightRadius: "12px",
		p: 3,
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		borderBottom: "1px solid #F1F1F1",
		mt: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 18,
			fontWeight: 600,
		},
	},
};

const columns = [
	{
		field: "full_name",
		headerName: "Name",
		sortable: false,
		flex: 1,
		renderCell: (params) => {
			return (
				<>
					<Avatar
						src={params.row?.profile_image}
						sx={{ bgcolor: "gray", mr: 1.5, width: 35, height: 35 }}
					>
						{params.row?.full_name?.charAt(0)}
					</Avatar>
					{params.row?.full_name}
				</>
			);
		},
	},
	{
		field: "email",
		headerName: "Email",
		sortable: false,
		flex: 1,
		valueGetter: (params) => params.row?.email,
	},
	{
		field: "mobile_number",
		headerName: "Mobile Number",
		sortable: false,
		flex: 1,
		valueGetter: (params) => params.row?.mobile_number,
	},
];

function RecentMembers({ members, loading }) {
	return (
		<>
			<Box sx={styles.header}>
				<Typography component="h2">Recent Members</Typography>
			</Box>

			<Listing
				rows={members || []}
				columns={columns}
				className="courses"
				mode="client"
				loading={loading}
			/>
		</>
	);
}

export default RecentMembers;
