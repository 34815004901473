import React, { useState } from "react";
import Listing from "../../components/ui/Listing";
import {
  ADMIN_MEMBERS_ATTENDEE_LISTING,
  ADMIN_MEMBERS_LISTING_SEARCH,
  ADMIN_MEMBER_TYPE_CHANGE,
  ADMIN_SUB_ADMIN_STATUS,
} from "../../data/constants/apiRoutes";
import { get, post } from "../../server";
import queryNames from "../../data/constants/queryNames";
import { Avatar, Button, IconButton, Stack, Tooltip } from "@mui/material";
import PageHeader from "../../components/ui/PageHeader";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import BoxModal from "../../components/Modals/BoxModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const styles = {
  button: {
    textTransform: "capitalize",
    color: "#0F4590",
    borderColor: "#0F4590",
    "&:hover": {
      color: "#0F4590",
    },
  },
};

const Attendee = () => {
  const [modal, setModal] = useState(false);
  const [member, setMember] = useState();
  const [id, setId] = useState(null);

  const [search, setSearch] = useState("");
  const [finalSearch, setFinalSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center">
              <Avatar
                src={params.row.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params.row.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params.row.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 300,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      sortable: false,
      width: 200,
      valueGetter: (params) => params.row.mobile_number || "-",
    },
    {
      field: "address",
      headerName: "Address",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row.address || "-",
    },
    {
      field: "is_active",
      headerName: "Status",
      sortable: false,
      width: 100,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Blocked"),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 170,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Change Status to Member">
              <Button
                variant="outlined"
                sx={styles.button}
                onClick={() => {
                  setMember(params.row);
                  setModal("confirm");
                }}
              >
                Upgrade
              </Button>
            </Tooltip>
            {!params.row.is_active ? (
              <Tooltip title="Enable">
                <IconButton
                  sx={{ color: "green" }}
                  onClick={() => {
                    setId(params.row.id);
                    setModal("enable");
                  }}
                >
                  <DoneOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Disable">
                <IconButton
                  sx={{ color: "red" }}
                  onClick={() => {
                    setId(params.row.id);
                    setModal("disable");
                  }}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  const client = useQueryClient();

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.MEMBERS);
  };

  const fetchMembers = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;

    if (isSearchParam === false) {
      url = new URL(ADMIN_MEMBERS_ATTENDEE_LISTING);
    } else if (isSearchParam && searchParam.length) {
      url = new URL(ADMIN_MEMBERS_LISTING_SEARCH);
      url.searchParams.append("search", searchParam);
      url.searchParams.append("type", "attendee");
    }

    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);

    return await get(url.toString());
  };

  const handleClose = () => {
    setMember(null);
    setModal(false);
    handleInvalidate();
  };

  const handleAction = async () => {
    if (modal === "confirm") {
      const { status, message } = await post(
        `${ADMIN_MEMBER_TYPE_CHANGE}/${member?.id}`,
        { is_member: 1 }
      );
      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        handleClose();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } else {
      const { status, message } = await get(`${ADMIN_SUB_ADMIN_STATUS}/${id}}`);
      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        handleClose();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const mutation = useMutation(handleAction, {
    onSuccess: () => {
      client.invalidateQueries([queryNames]);
    },
  });

  const handleConfirm = () => {
    mutation.mutate();
  };

  const handleSearchClick = () => {
    if (search.length) {
      setIsSearch(true);
      setFinalSearch(search);
    }
  };

  const handleCancleSearch = () => {
    setIsSearch(false);
    setSearch("");
  };

  return (
    <>
      <PageHeader
        title="Attendees"
        placeholder="Search Attendees"
        searchInput={true}
        search={search}
        setSearch={setSearch}
        isSearch={isSearch}
        handleCancleSearch={handleCancleSearch}
        handleSearchClick={handleSearchClick}
      />

      <Listing
        columns={columns}
        queryName={queryNames.MEMBERS}
        fetchRecords={fetchMembers}
        type="courses"
        search={finalSearch}
        isSearch={isSearch}
      />

      <BoxModal
        header="Are you sure?"
        title={
          modal === "confirm"
            ? `Do you want to change Status of ${member?.full_name} to Member`
            : modal === "enable"
            ? "Do you want to enable this member?"
            : "Do you want to disable this member?"
        }
        open={modal === "enable" ? "confirm" : modal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        confirmButton={
          modal === "confirm"
            ? "Approve"
            : modal === "enable"
            ? "Enable"
            : "Disable"
        }
        confirmButtonColor={
          modal === "confirm" ? "#2daf40" : modal === "enable" ? "green" : "red"
        }
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default Attendee;
