const routes = {
  UNAUTHORIZE: "/unauthorize",
  NOTFOUND: "/notFound",
  ADMIN_LOGIN: "/login",
  ADMIN_FORGET_PASSWORD: "/forgotPassword",
  ADMIN_VERIFY_OTP: "/verifyOTP",
  ADMIN_RESET_PASSWORD: "/resetPassword",
  ADMIN_COURSE_DASHBOARD: "/courses",
  ADMIN_ALL_COURSE: "/allCourses",
  ADMIN_PAST_EVENTS: "/events/past-events",
  ADMIN_SCHEDULED_EVENTS: "/events/scheduled-events",
  ADMIN_FEATURED_EVENTS: "/events/featured-events",
  ADMIN_COMPANY: "/companies",
  ADMIN_COMPANY_ADD: "/companies/add",
  ADMIN_COMPANY_UPDATE: "/companies/edit",
  ADMIN_SPONSOR: "/sponsors",
  ADMIN_SPONSOR_ADD: "/sponsors/add",
  ADMIN_SPONSOR_UPDATE: "/sponsors/edit",
  ADMIN_FORUM_LIST: "forum-list",
  ADMIN_FORUM: "forum",
  Reported_Post : "ReportedPost",


  ADMIN_HOME: "/home",
};

export default routes;
