import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Fab,
	LinearProgress,
	Stack,
	Typography,
} from "@mui/material";
import FormInput from "../../../../components/Forms/FormInput";
import { useSnackbar } from "notistack";
import styles from "../../../../assets/styles/events/createEvent.styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { get } from "../../../../server";
import { ADMIN_EVENT_MEMBER_USER_EXISTS } from "../../../../data/constants/apiRoutes";
import { useQuery } from "react-query";

const MemberTab = ({
	members,
	setMembers,
	loading = false,
	eventForm,
	setEventForm,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	const [typedEmail, setTypedEmail] = useState("");
	const [searchedEmails, setSearchedEmails] = useState([]);
	const [enableSearch, setEnableSearch] = useState(false);

	const handleEmailAdd = (index) => {
		const selectedUser = searchedEmails[index];
		const newEmail = {
			profile_image: selectedUser.profile_image,
			full_name: selectedUser.full_name,
			email: selectedUser.email,
			id: selectedUser.id,
		};

		setMembers([...members, newEmail]);

		const updatedSearchedEmails = searchedEmails.filter(
			(user, i) => i !== index
		);
		setSearchedEmails(updatedSearchedEmails);
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailRegex.test(email);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const handleCheckboxChange = (member, checked) => {
		if (checked) {
			if (member.id) {
				setEventForm((prevForm) => ({
					...prevForm,
					members: {
						...prevForm.members,
						member_id: [...prevForm.members.member_id, member.id],
					},
				}));
			} else {
				setEventForm((prevForm) => ({
					...prevForm,
					members: {
						...prevForm.members,
						members_email: [...prevForm.members.members_email, member.email],
					},
				}));
			}
		} else {
			if (member.id) {
				// Remove the email from eventForm.members.members_email
				setEventForm((prevForm) => ({
					...prevForm,
					members: {
						...prevForm.members,
						member_id: prevForm.members.member_id.filter(
							(id) => id !== member.id
						),
					},
				}));
			} else
				setEventForm((prevForm) => ({
					...prevForm,
					members: {
						...prevForm.members,
						members_email: prevForm.members.members_email.filter(
							(email) => email !== member.email
						),
					},
				}));
		}
	};

	const findMember = async () => {
		let url;
		url = new URL(ADMIN_EVENT_MEMBER_USER_EXISTS);
		url.searchParams.append("email", typedEmail);

		const { status, message, data } = await get(url.toString());
		if (status) {
			const filteredData = data.filter(
				(newMember) =>
					!members.some((member) => member.email === newMember.email)
			);
			setSearchedEmails(filteredData);
		}
		if (!data.length) {
			if (isValidEmail(typedEmail)) {
				const isEmailInMembers = members.some(
					(member) => member.email === typedEmail
				);
				if (isEmailInMembers) {
					setSearchedEmails([]);
				} else {
					const newEmail = {
						profile_image: null,
						full_name: typedEmail,
						email: typedEmail,
						type: "not_exist",
					};
					setSearchedEmails([newEmail]);
				}
			}
		}
		setEnableSearch(false);
	};

	const memberQuery = useQuery(["membersM", enableSearch], findMember, {
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<Box>
				<FormInput
					label="Invite Members via Link"
					placeholder="https//example@yourmail.com"
					id="invite-link-input"
					value={typedEmail}
					onChange={(e) => {
						setTypedEmail(e.target.value);
						if (e.target.value.length > 2) {
							setEnableSearch(true);
						}
						if (e.target.value.length === 0) {
							setSearchedEmails([]);
						}
					}}
				/>
			</Box>

			{(memberQuery.isLoading || memberQuery.isFetching) && (
				<Box width="100%" textAlign="center">
					<LinearProgress />
				</Box>
			)}

			{searchedEmails.length > 0 && (
				<Box sx={{ border: "1px solid #eee", mt: 2, borderRadius: "10px" }}>
					{searchedEmails.map((member, index) => (
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							key={index}
							sx={styles.memberFullStack}
						>
							<Stack direction="row" sx={styles.memberStack}>
								<Avatar sx={styles.memberAvatar} src={member.profile_image} />
								<Stack>
									<Typography component="h4">{member.full_name}</Typography>
									<Typography component="h4" flex={1}>
										{member.email}
									</Typography>
								</Stack>
							</Stack>
							<Button
								variant="contained"
								sx={styles.addEmail}
								onClick={() => handleEmailAdd(index)}
							>
								{member.type === "not_exist" ? "Invite" : "Add Email"}
							</Button>
						</Stack>
					))}
				</Box>
			)}

			<Box mt={3}>
				{members.length > 0 ? (
					members.map((member, index) => (
						<Stack direction="row" sx={styles.memberStack} key={index}>
							<Avatar sx={styles.memberAvatar} src={member.profile_image}>
								{member.full_name?.charAt(0)}
							</Avatar>
							<Stack sx={styles.memberNameStack} gap="5px">
								<Typography component="h4">{member.full_name}</Typography>
								<Typography component="label">
									{member.id && member.email}
								</Typography>
							</Stack>
							<Checkbox
								icon={
									<RadioButtonUncheckedIcon
										sx={{ "& path": { color: "#E2E3E4" } }}
									/>
								}
								checkedIcon={
									<CheckCircleIcon sx={{ "& path": { color: "#03989E" } }} />
								}
								sx={styles.memberCheckbox}
								size="medium"
								checked={
									!!eventForm.members.member_id.find(
										(newMember) => newMember === member.id
									) ||
									!!eventForm.members.members_email.find(
										(newMember) => newMember === member.email
									) ||
									false
								}
								onChange={(e) => handleCheckboxChange(member, e.target.checked)}
							/>
						</Stack>
					))
				) : (
					<Box textAlign="center">
						{loading ? (
							<CircularProgress sx={{ textAlign: "center" }} />
						) : (
							<Typography>No members available.</Typography>
						)}
					</Box>
				)}
			</Box>

			<Fab
				sx={{
					...styles.scrollButtonStyles,
					...(isVisible && styles.visibleScrollButtonStyles),
				}}
				onClick={scrollToTop}
			>
				<KeyboardArrowUpIcon />
			</Fab>
		</>
	);
};

export default MemberTab;
