import { ReactComponent as Dashboard } from "../assets/svg/sidebarIcons/Dashboard.svg";
import { ReactComponent as Courses } from "../assets/svg/sidebarIcons/Courses.svg";
import { ReactComponent as Companies } from "../assets/svg/sidebarIcons/Companies.svg";
import { ReactComponent as Members } from "../assets/svg/sidebarIcons/Members.svg";
import { ReactComponent as Documents } from "../assets/svg/Document.svg";
import { ReactComponent as Ads } from "../assets/svg/sidebarIcons/Ads.svg";
import { ReactComponent as Events } from "../assets/svg/sidebarIcons/Events.svg";
import { ReactComponent as Sales } from "../assets/svg/sidebarIcons/Sales.svg";
import { ReactComponent as Payment } from "../assets/svg/sidebarIcons/Payment.svg";
import { ReactComponent as Emails } from "../assets/svg/sidebarIcons/Emails.svg";
import { ReactComponent as Polls } from "../assets/svg/sidebarIcons/Polls.svg";
import { ReactComponent as Settings } from "../assets/svg/sidebarIcons/Settings.svg";
import { ReactComponent as Interest } from "../assets/svg/sidebarIcons/Interest.svg";
import { ReactComponent as NewEvents } from "../assets/svg/sidebarIcons/NewEvents.svg";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { ReactComponent as Sponsor } from "../assets/svg/sidebarIcons/Sponsors.svg";

const routes = [
	{
		label: "Dashboard",
		icon: <Dashboard />,
		path: "/home",
	},
	{
		label: "Manage Courses",
		icon: <Courses />,
		path: "/courses",
	},
	{
		label: "Manage Companies",
		icon: <Companies />,
		path: "/companies",
	},
	{
		label: "Manage Subadmins",
		icon: <SupervisorAccountOutlinedIcon />,
		path: "/subadmins",
	},
	{
		label: "Manage Member Requests",
		icon: <PersonAddOutlinedIcon />,
		path: "/user-requests",
	},
	{
		label: "Interests",
		icon: <Interest />,
		path: "/interest",
	},
	{
		label: "Promo Codes",
		icon: <DiscountOutlinedIcon />,
		path: "/promoCodes",
	},
	{
		label: "Exhibitors / Booths",
		icon: <Sponsor />,
		path: "/sponsors",
	},
	{
		label: "Forum List",
		icon: <Documents />,
		path: "/forum-list",
	},
	{
		label: "Reported Post",
		icon: <Documents />,
		path: "/ReportedPost",
	},
	{
		label: "Events",
		icon: <NewEvents />,
		path: "/events",
		subCategories: [
			// {
			// 	label: "All Events",
			// 	path: "/events/all-events",
			// 	type: 1,
			// },
			{
				label: "Scheduled Events",
				path: "/events/scheduled-events",
				type: 1,
			},
			{
				label: "Featured Events",
				path: "/events/featured-events",
				type: 1,
			},
			{
				label: "Past Events",
				path: "/events/past-events",
				type: 1,
			},
			// {
			// 	label: "Forum List",
			// 	path: "/events/forum-list",
			// 	type: 1,
			// },
		],
	},
	{
		label: "Manage Members",
		icon: <Members />,
		path: "/members",
		subCategories: [
			{
				label: "Members / Realtist",
				path: "/members",
				type: 1,
			},
			{
				label: "Attendees",
				path: "/members/attendee",
				type: 1,
			},
		],
	},
	// {
	// 	label: "Scheduled Events",
	// 	icon: <Events />,
	// 	path: "/scheduledEvents",
	// },
	{
		label: "Sales",
		icon: <Sales />,
		path: "/sales",
		subCategories: [
			{
				label: "Courses",
				path: "/sales/courses",
				type: 1,
			},
			{
				label: "Event Tickets",
				path: "/sales/tickets",
				type: 1,
			},
		],
	},
	// {
	// 	label: "Payment",
	// 	icon: <Payment />,
	// 	path: "/payment",
	// 	subCategories: [
	// 		{
	// 			label: "Subscription",
	// 			path: "/payment/subscription",
	// 			type: 1,
	// 		},
	// 		{
	// 			label: "Sponsor",
	// 			path: "/payment/sponsor",
	// 			type: 1,
	// 		},
	// 		{
	// 			label: "Discount",
	// 			path: "/payment/discount",
	// 			type: 1,
	// 		},
	// 	],
	// },
	{
		label: "Bulk Emails",
		icon: <Emails />,
		path: "/emails",
	},
	{
		label: "Uploaded Images",
		icon: <ImageOutlinedIcon />,
		path: "/images",
	},
	// {
	// 	label: "Manage Polls",
	// 	icon: <Polls />,
	// 	path: "/manage-polls",
	// },
	{
		label: "Settings",
		icon: <Settings />,
		path: "/settings",
	},
];

export { routes };
