const queryNames = {
    PROFILE: "profile",
    PROMO_CODE: "promoCode",
    COMPANY: "company",
    COURSES: "courses",
    INTEREST: "interest",
    EVENTS: "events",
    MEMBERS: "members",
    SUB_ADMIN: "subadmins",
    EMAILS: "emails",
    COUNTRY: "country",
    DASHBOARD: "dashboard",
    SPONSOR: "sponsor",
    SPEAKERS: "speakers",
    SALES: "sales",
    SALE_DETAIL: "saleDetail",
    EVENT_STATS: "eventStats",
    NOTIFICATION: "notification",
    EVENT_IMAGES: "eventImages",
    TICKET_SESSION: "ticketSession",
    TICKET_SALES: "ticketSales",
    FOURMS : "fourms",
    FORUMS : "forums",
    POSTS: "posts",
};

export default queryNames;
