import React, { useEffect, useRef, useState } from "react";
import FormAutoComplete from "../../../../components/Forms/FormAutoComplete";
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import styles from "../../../../assets/styles/events/createEvent.styles";
import FormInput from "../../../../components/Forms/FormInput";
import FormLabel from "../../../../components/Forms/FormLabel";
import { TimePicker } from "@mui/x-date-pickers";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CropImage from "../../../../components/Modals/CropImage";
import LoadingButton from "../../../../components/Buttons/LoadingButton";
import { useSnackbar } from "notistack";
import { post } from "../../../../server";
import { useMutation } from "react-query";
import { ADMIN_EVENT_CREATE } from "../../../../data/constants/apiRoutes";
import GetAddressModal from "../../../../components/Modals/GetAddressModal";
import moment from "moment";
import { validator } from "../../../../utils/helpers/validator";
import {
  NewSponsorSchema,
  SponsorSchema,
} from "../../../../utils/schemas/EventSchema";
import _ from "lodash";
import { uploadImageType } from "../../../../data/constants/uploadImageTypes";
import awsFileUpload from "../../../../utils/helpers/awsFileUpload";
import LocationAutoComplete from "../../../../components/Forms/LocationAutoComplete";

const initialState = {
  sponser_name: "",
  email: "",
  company_name: "",
  website: "",
  address: "",
  latitude: "",
  longitude: "",
  description: "",
  image: "",
  other: false,
};

const SponsorTab = ({
  sponsors,
  currentStep,
  setCurrentStep,
  eventForm,
  setEventForm,
}) => {
  const [newSponsors, setNewSponsors] = useState(initialState);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  const coverRef = useRef();

  const [isCoverHovered, setIsCoverHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [modal, setModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);

  const [cover_image, setCoverImage] = useState();

  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (eventForm.members?.sponsors[0]?.company_sponser_id) {
      const newValue = sponsors?.find(
        (option) =>
          option.id === eventForm.members?.sponsors[0]?.company_sponser_id
      );

      setNewSponsors({
        sponser_name: newValue?.sponser_name,
        company_name: newValue?.company_name,
        email: newValue?.email,
        description: newValue?.description,
        website: newValue?.website,
        address: newValue?.address,
        image: newValue?.image,
        id: newValue?.id,
      });
    }
  }, [eventForm.members?.sponsors[0]?.company_sponser_id]);

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    // setEventForm({
    // 	...eventForm,
    // 	members: {
    // 		...eventForm.members,
    // 		sponsors: {
    // 			...eventForm.members.sponsors,
    // 			[name]: [value],
    // 		},
    // 	},
    // });
    setNewSponsors({ ...newSponsors, [name]: value });
  };

  const handleCompanyChange = (event, newValue) => {
    if (newValue)
      if (newValue?.label === "Others..") {
        setNewSponsors({
          ...initialState,
          other: true,
        });
        setEventForm({
          ...eventForm,
          members: {
            ...eventForm.members,
            sponsors: [],
          },
        });
        setAddress("");
      } else {
        setEventForm({
          ...eventForm,
          members: {
            ...eventForm.members,
            sponsors: [{ company_sponser_id: newValue?.id, id: newValue?.id }],
            company_sponsors: newValue,
          },
        });
        setNewSponsors({
          sponser_name: newValue?.sponser_name,
          company_name: newValue?.company_name,
          email: newValue?.email,
          description: newValue?.description,
          website: newValue?.website,
          address: newValue?.address,
          image: newValue?.image,
          id: newValue?.id,
        });
        setAddress(newValue?.address);
      }
    else {
      setEventForm({
        ...eventForm,
        members: {
          ...eventForm.members,
          sponsors: [],
          company_sponsors: "",
        },
      });
      setNewSponsors(initialState);
    }
  };

  const handleCoverClick = () => {
    coverRef.current.click();
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    if (file.size <= maxSizeInBytes) {
      setCoverImage(URL.createObjectURL(file));
      setModal("eventSponsor");
    } else {
      alert("Selected cover image exceeds the 15MB size limit.");
    }
  };

  const handleCoverMouseEnter = () => {
    setIsCoverHovered(true);
  };

  const handleCoverMouseLeave = () => {
    setIsCoverHovered(false);
  };

  const handleAddressClick = () => {
    setAddressModal("address");
  };

  const saveLocation = (address, location) => {
    setNewSponsors({
      ...newSponsors,
      address: address,
      latitude: location.latitude,
      longitude: location.longitude,
    });
  };

  const handleAddressChange = ({ address, lat, long }) => {
    setNewSponsors({
      ...newSponsors,
      address: address,
      latitude: lat,
      longitude: long,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const validateForm = () => {
    let newErrors;
    if (!newSponsors.other)
      newErrors = validator(
        {
          company_sponser_id:
            eventForm.members?.sponsors[0]?.company_sponser_id || null,
        },
        SponsorSchema
      );
    else newErrors = validator(newSponsors, NewSponsorSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    if (newSponsors.other && !newSponsors.image) {
      enqueueSnackbar("Please attach Cover Image", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleSaveEventMembers = async () => {
    const payload = _.cloneDeep(newSponsors);
    const eventPayload = _.cloneDeep(eventForm.members);
    let newStatus = true;

    if (eventPayload.guest_speakers.length > 0) {
      await Promise.all(
        eventPayload.guest_speakers.map(async (speaker) => {
          if (typeof speaker.image !== "string") {
            const data = await awsFileUpload(
              speaker.image,
              uploadImageType.createSpeaker
            );

            if (data.status === true) {
              speaker.image = data.location;
            }
          }
        })
      );
    }

    if (!eventPayload.guest_speakers[0].name) {
      eventPayload.guest_speakers = [];
    }

    if (payload.other === true) {
      if (typeof payload.image === "object") {
        const data = await awsFileUpload(
          payload.image,
          uploadImageType.sponsorProfile
        );
        if (data.status === true) payload.image = data.location;
        else {
          enqueueSnackbar("Error uploading", { variant: "error" });
          newStatus = false;
        }
      }
      if (newStatus) {
        delete payload.other;
        const { status, message, data } = await post(ADMIN_EVENT_CREATE, {
          ...eventPayload,
          sponsors: [
            {
              ...payload,
            },
          ],
        });
        if (status === true) {
          const newSpeakers = data?.speakers
            ?.filter(
              (speaker) => speaker?.event_speaker?.is_guest_speaker === false
            )
            .map((speaker) => ({
              value: speaker?.id,
              id: speaker?.event_speaker?.id,
              label: speaker?.event_speaker.full_name,
              full_name: speaker?.event_speaker.full_name,
              email: speaker?.event_speaker.email,
              profile_image: speaker?.event_speaker.profile_image,
            }));
          const newGuestSpeakers = data?.speakers
            ?.filter(
              (speaker) => speaker?.event_speaker?.is_guest_speaker === true
            )
            .map((speaker) => ({
              value: speaker?.id,
              id: speaker?.event_speaker?.id,
              label: speaker?.event_speaker.full_name,
              name: speaker?.event_speaker.full_name,
              email: speaker?.event_speaker.email,
              image: speaker?.event_speaker.profile_image,
              bio: speaker?.event_speaker?.description,
              phone: speaker?.event_speaker?.mobile_number,
              address: speaker?.event_speaker?.address,
            }));

          setEventForm({
            ...eventForm,
            members: {
              ...eventForm.members,
              sponsors: [
                {
                  company_sponser_id: data?.sponsors[0]?.company_sponsor?.id,
                  id: data?.sponsors[0]?.company_sponsor?.id,
                },
              ],
              speakers: newSpeakers,
              guest_speakers: newGuestSpeakers,
            },
          });
          setCurrentStep(currentStep + 1);
        } else enqueueSnackbar(message, { variant: "error" });
      }
    } else {
      const { status, message, data } = await post(
        ADMIN_EVENT_CREATE,
        eventPayload
      );
      console.log(status, message, data);
      if (status === true) {
        const newSpeakers = data?.speakers
          ?.filter(
            (speaker) => speaker?.event_speaker?.is_guest_speaker === false
          )
          .map((speaker) => ({
            value: speaker?.id,
            id: speaker?.event_speaker?.id,
            label: speaker?.event_speaker.full_name,
            full_name: speaker?.event_speaker.full_name,
            email: speaker?.event_speaker.email,
            profile_image: speaker?.event_speaker.profile_image,
          }));
        const newGuestSpeakers = data?.speakers
          ?.filter(
            (speaker) => speaker?.event_speaker?.is_guest_speaker === true
          )
          .map((speaker) => ({
            value: speaker?.id,
            id: speaker?.event_speaker?.id,
            label: speaker?.event_speaker.full_name,
            name: speaker?.event_speaker.full_name,
            email: speaker?.event_speaker.email,
            image: speaker?.event_speaker.profile_image,
            bio: speaker?.event_speaker?.description,
            phone: speaker?.event_speaker?.mobile_number,
            address: speaker?.event_speaker?.address,
          }));
        const newSponsors = data?.sponsors?.map((sponsor) => ({
          id: sponsor?.company_sponsor?.id,
          label: sponsor?.company_sponsor?.company_name,
        }));
        setEventForm({
          ...eventForm,
          members: {
            ...eventForm.members,
            speakers: newSpeakers,
            newSponsors: newSponsors,
            guest_speakers: newGuestSpeakers,
          },
        });

        // enqueueSnackbar(message, { variant: "success" });
        setCurrentStep(currentStep + 1);
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleSaveEventMembers);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
    // setCurrentStep(currentStep + 1);
  };

  const handleSkip = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Stack direction="row" sx={styles.title}>
        <Typography component="h1">Sponsor Details</Typography>
        <Button variant="contained" onClick={handleSkip} sx={{ mr: 1 }}>
          Skip{" "}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Continue
        </LoadingButton>
      </Stack>
      <Grid container>
        <Grid item md={4} sm={12}>
          <FormAutoComplete
            label="Sponsors Company"
            placeholder="Select Sponsor"
            options={sponsors || []}
            value={
              eventForm.members?.sponsors[0]?.company_sponser_id
                ? sponsors?.find(
                    (option) =>
                      option?.id ===
                      eventForm.members?.sponsors[0]?.company_sponser_id
                  )
                : newSponsors?.other
                ? { label: "Others.." }
                : null
            }
            onChange={handleCompanyChange}
            isOptionEqualToValue={(option, value) =>
              option?.label === value?.label
            }
            error={errors?.company_sponser_id}
            helperText={errors?.company_sponser_id || ""}
          />
        </Grid>
      </Grid>
      <Box component="div" sx={styles.dividerContainer}>
        <Divider style={styles.divider2} />
        <Typography component="span">OR</Typography>
        <Divider style={styles.divider2} />
      </Box>
      <Grid container spacing={3} mb={2}>
        <Grid item md={4} sm={12}>
          <FormInput
            label="Add Company"
            placeholder="Company"
            value={newSponsors?.company_name}
            name="company_name"
            onChange={handleChange}
            error={errors?.company_name}
            helperText={errors?.company_name || ""}
          />
        </Grid>

        <Grid item md={4} sm={12}>
          <FormInput
            label="Sponsor Name"
            placeholder="Sponsor name"
            value={newSponsors?.sponser_name}
            name="sponser_name"
            onChange={handleChange}
            error={errors?.sponser_name}
            helperText={errors?.sponser_name || ""}
          />
        </Grid>

        <Grid item md={4} sm={12}>
          <FormInput
            label="Email"
            placeholder="Email"
            value={newSponsors?.email}
            name="email"
            type="email"
            onChange={handleChange}
            error={errors?.email}
            helperText={errors?.email || ""}
          />
        </Grid>

        <Grid item md={8} sm={12}>
          <FormInput
            label="Description"
            placeholder="Description"
            value={newSponsors?.description}
            name="description"
            onChange={handleChange}
            multiline
            rows={4}
            error={errors?.description}
            helperText={errors?.description || ""}
          />
        </Grid>
        <Grid item md={4} sm={12}></Grid>

        <Grid item md={4} sm={12}>
          <FormInput
            label="Website"
            placeholder="Website"
            value={newSponsors?.website}
            name="website"
            type="url"
            onChange={handleChange}
            error={errors?.website}
            helperText={errors?.website || ""}
          />
        </Grid>

        <Grid item md={8} sm={12}>
          <FormInput
            label="Address"
            placeholder="Address"
            value={newSponsors?.address}
            name="address"
            onClick={handleAddressClick}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              "& input": {
                cursor: "pointer",
                bgcolor: "rgba(226, 227, 228, 0.2)",
                height: 40,
                boderRadius: "4px",
              },
            }}
            error={errors?.address}
            helperText={errors?.address || ""}
          />
        </Grid>
      </Grid>
      <FormLabel label="Cover Image" />
      {!newSponsors.image ? (
        <Box sx={styles.eventImageContainer}>
          <Typography component="label">+Add Cover Image</Typography>
          <Button variant="outlined" onClick={handleCoverClick}>
            Browse Image
          </Button>
          <input
            type="file"
            hidden
            onChange={handleCoverChange}
            id={`documentUpload`}
            ref={coverRef}
            accept="image/*"
          />
        </Box>
      ) : (
        <Box
          position="relative"
          onMouseEnter={handleCoverMouseEnter}
          onMouseLeave={handleCoverMouseLeave}
        >
          <Box
            component="img"
            src={
              typeof newSponsors?.image === "string"
                ? newSponsors?.image
                : URL.createObjectURL(newSponsors?.image)
            }
            sx={{
              ...styles.coverImageContainer,
              opacity: isCoverHovered ? 0.3 : 1,
              transition: "opacity 0.3s ease",
            }}
            loading="lazy"
          />
          <Stack
            sx={{
              ...styles.coverHover,
              display: isCoverHovered ? "flex" : "none", // Control visibility
              opacity: isCoverHovered ? 1 : 0.5, // Control opacity
            }}
          >
            <Typography component="label">+Add Cover Image</Typography>
            <Button variant="outlined" onClick={handleCoverClick}>
              Browse Image
            </Button>
          </Stack>
        </Box>
      )}
      <Fab
        sx={{
          ...styles.scrollButtonStyles,
          ...(isVisible && styles.visibleScrollButtonStyles),
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>
      <CropImage
        modal={modal}
        setModal={setModal}
        image={cover_image}
        user={newSponsors}
        setUser={setNewSponsors}
        ratio={16 / 9}
      />
      <GetAddressModal
        modal={addressModal === "address" || false}
        setModal={setAddressModal}
        saveLocation={saveLocation}
      />
    </>
  );
};

export default SponsorTab;
