import {
  Grid,
  Typography,
  Stack,
  Box,
  Button,
  Avatar,
  AvatarGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import Listing from "../../../components/ui/Listing";
import BannerImg from "../../../assets/images/EventsBanner.jpg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ReactComponent as Documents } from "../../../assets/svg/Document.svg";
import { ReactComponent as Photos } from "../../../assets/svg/Photos.svg";
import { ReactComponent as Sponsors } from "../../../assets/svg/Sponsors.svg";
import { ReactComponent as Speakers } from "../../../assets/svg/Speakers.svg";
import { ReactComponent as Agenda } from "../../../assets/svg/Agenda.svg";
import CompanyLogo from "../../../assets/images/CompanyLogo.png";
import ReservationChart from "../../../components/pageComponents/EventDetails/ReservationChart";
import SalesReport from "../../../components/pageComponents/EventDetails/SalesReport";
import Directions from "../../../components/pageComponents/EventDetails/Directions";
import { useNavigate, useParams } from "react-router-dom";
import queryNames from "../../../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { get , destroy } from "../../../server";
import {
  ADMIN_EVENT_DETAIL,
  ADMIN_EVENT_FEATURE,
  ADMIN_FORUM_BY_ID,
  ADMIN_FORUM_DETAIL,
  ADMIN_FORUM_COMMENTS,
  ADMIN_FORUM_COMMENTS_DELETE
} from "../../../data/constants/apiRoutes";
import BoxModal from "../../../components/Modals/BoxModal";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";
import moment from "moment";
import styles from "../../../assets/styles/events/eventDetails.styles";
import Loader from "../../../components/overlay/Loader";
import LoadingButton from "../../../components/Buttons/LoadingButton";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text?.slice(0, 250) : text}
      {!isReadMore && (
        <div onClick={toggleReadMore} className="read">
          {isReadMore ? "Read more" : " Read less"}
        </div>
      )}
    </>
  );
};

function ForumDetailPage() {
  const [eventDetail, setEventDetail] = useState();
  const [commentDetail, setCommentDetail] = useState();
  const [deleteItem, setDeleteItem] = useState(null);
  const [modal, setModal] = useState(false);
  const [updateForums, setUpdateForums] = useState(null);

  const { forumId } = useParams();
  console.log({ forumId })
  const { enqueueSnackbar } = useSnackbar();

  const isId = forumId !== null && typeof forumId !== "undefined";

  const fetchEventDetail = async () => {
    const { status, message, data } = await get(
      `${ADMIN_FORUM_BY_ID}/${forumId}`
    );
    console.log({ status, message, data })
    if (status === true) {
      setEventDetail(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  
  const fetchCommentDetail = async () => {
    try {
      const response = await get(`${ADMIN_FORUM_COMMENTS}/${forumId}`);
      if (response.status === true) {
        setCommentDetail(response.data.data); // Accessing the array inside the "data" field
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching forum comments: ", error);
      enqueueSnackbar("Failed to fetch forum comments", { variant: "error" });
    }
  };
  const fetchForums = async (pageNo,limit, direction = null, id) => {
		let url = new URL(`${ADMIN_FORUM_COMMENTS}/${forumId}`);
	
		url.searchParams.append(direction, id);
		url.searchParams.append("page", pageNo + 1);
		
	
		return await get(url.toString());
	  };

  // const commentsQuery = useQuery([queryNames.COMMENTS, forumId], fetchCommentDetail, {
  //   enabled: isId,
  //   keepPreviousData: true,
  // });
  const eventDetailQuery = useQuery([queryNames.EVENTS, forumId], fetchEventDetail, {
    enabled: isId,
    keepPreviousData: true,
  });

  // Fetch comment detail
  const commentsQuery = useQuery([queryNames.COMMENTS, forumId], fetchCommentDetail, {
    enabled: isId,
    keepPreviousData: true,
  });
  const { isLoading } = useQuery([queryNames.FORUMS], fetchForums, {
		enabled: !modal,
		refetchOnWindowFocus: false,
	});
  const handleDelete = async (row) => {
		const { status, message } = await destroy(`${ADMIN_FORUM_COMMENTS_DELETE}/${row.id}`);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			// handleInvalidate();
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutationDelete = useMutation(handleDelete);

	const handleConfirmDelete = () => {
		// Assuming you have the row available, pass it to the delete function
		mutationDelete.mutate(deleteItem);
	};
  const Comment = ({ comment }) => {
    return (
      <div>
        <p>{comment.description}</p>
        {/* Render other details of the comment's owner if needed */}
        {/* <p>Created By: {comment.owner.full_name}</p> */}
        {/* Additional comment details can be displayed here */}
      </div>
    );
  };

  useEffect(() => {
    // Fetch data and update state as needed
    // ...

    // For example, updating comments:
    // Assuming commentDetail is set from the fetched data
    // Set the comment details accordingly
    const fetchedComments = []; // Replace this with actual fetched comments
    setCommentDetail(fetchedComments);
  }, []);

  // if (isLoading || isFetching) return <Loader />;


  // const { isLoading, isFetching } = useQuery(
  //   [queryNames.EVENTS, forumId],
  //   fetchEventDetail,
  //   {
  //     enabled: isId,
  //     keepPreviousData: true,
  //   }
  // );
  if (eventDetailQuery.isLoading || eventDetailQuery.isFetching || commentsQuery.isLoading || commentsQuery.isFetching) {
    return <Loader />;
  }
  const formatDate = (date) => {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
		let hour = date.getHours().toString().padStart(2, '0');
		let minute = date.getMinutes().toString().padStart(2, '0');

		return `${year}-${month}-${day} ${hour}:${minute}`;
	}
  const handleClose = () => {
		setModal(false);
		// setAction(false);
		// setNewForums(initialState);
		// setCoverImage(null);
    setUpdateForums(null);
	};

  // if (isLoading || isFetching) return <Loader />;
  const columns = [
		
		
		// {
		// 	field: "isFav",
		// 	headerName: "Like",
		// 	sortable: false,
		// 	flex: 1,
		// 	// valueGetter: (params) => params.row.company_sponsor.website,
		// },
    // {
		// 	field: "Full Name",
		// 	headerName: "updated Date",
		// 	sortable: false,
		// 	flex: 1,
    //   valueGetter: (params) => params.row.owner.full_name,
		// 	// valueFormatter: (params) => formatDate(new Date(params.value)),
		// },
    {
			field: "name",
			headerName: "Full Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Stack
							direction="row"
							alignItems="center"
							sx={{ cursor: "pointer" }}
						>
							<Avatar
								src={params.row.owner.profile_image}
								sx={{
									bgcolor: "gray",
									mr: 1.5,
									width: 35,
									height: 35,
								}}
							>
								{params.row.owner.full_name
									.charAt(0)
									.toUpperCase()}
							</Avatar>
							{params.row.owner.full_name}
						</Stack>
					</>
				);
			},
		},
    {
      field: 'description', // Assuming 'comments' is the field where comment text is stored
      headerName: 'Comments',
      sortable: false,
      flex: 1,
    },
		{
			field: "created_at",
			headerName: "Created Date",
			sortable: false,
			flex: 1,
			valueFormatter: (params) => formatDate(new Date(params.value)),
		},

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Stack direction="row">
						{/* <Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => {
									handleEdit(params.row);
									setModal("Edit");
								}}

							>
								<Edit />
							</IconButton>
						</Tooltip> */}
						<Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setDeleteItem(params.row);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
						{/* <Tooltip title="View Details" arrow placement="top">
							<IconButton
								onClick={() => navigateToDetailPage(params.row)}
							>
								<Edit />

							</IconButton>
						</Tooltip> */}
					</Stack>
				);
			},
		},

		// {
		// 	field: "action",
		// 	headerName: "Action",
		// 	sortable: false,
		// 	width: 100,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Stack direction="row" spacing={0.5}>
		// 				<Tooltip title="Edit" arrow placement="top">
		// 					<IconButton
		// 						onClick={() => {
		// 							// setPromoCodeId(params.row.id);
		// 							setModal("detail");
		// 						}}
		// 					>
		// 						<Edit />
		// 					</IconButton>
		// 				</Tooltip>
		// 				<Tooltip title="Delete" arrow placement="top">
		// 					<IconButton
		// 						// onClick={() => {
		// 						// 	// setPromoCodeId(params.row.id);
		// 						// 	 setAction("delete");
		// 						// }}
		// 						onClick={() => handleConfirmDelete(params.row)}

		// 					>
		// 						<Delete />
		// 					</IconButton>
		// 				</Tooltip>
		// 			</Stack>
		// 		);
		// 	},
		// },


	];






  return (
    <Box sx={styles}>
      <Typography sx={styles.title}>Forum Details</Typography>
      <LoadingOverlay loading={eventDetailQuery.isLoading} />
      <Box sx={styles.wrapper}>
        <Grid container>
          <Grid md={12} item sx={styles.leftWrapper}>
            <Box sx={styles.banner}>
            {/* <Box
                component="img"
                src={
                  eventDetail?.assets.length > 0
                    ? eventDetail?.assets[0].url
                    : BannerImg
                }
                loading="lazy"
              /> */}
              {/* <Box component="img" src={eventDetail?.assets?.[0]?.asset_url} loading="lazy" /> */}
              {eventDetail?.assets?.[0]?.asset_url ? (
               <Box component="img" src={eventDetail?.assets?.[0]?.asset_url} loading="lazy" />
              ) : (
                 <Box component="img" src={BannerImg} loading="lazy" />
                 )}
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={styles.header}
            >
              <Stack direction="row" columnGap={1.5} alignItems="center">
                <Box
                  component="img"
                  src={eventDetail?.owner?.company_logo}
                  loading="lazy"
                />
                <Box>
                  <Typography component="p">Created By</Typography>
                  <Typography component="h3">
                    {eventDetail?.owner?.full_name}
                  </Typography>
                </Box>
              </Stack>

            </Stack>
            <Box sx={styles.detail}>
            <Typography component="h2">  
                Description
              </Typography>
              <Typography component="p">  
                <ReadMore> {eventDetail?.description}</ReadMore>
              </Typography>
              
              <Typography component="h6">
                {moment(eventDetail?.created_at).format(
                  "D-MMM-YYYY | h:mm A"
                )}

              </Typography>

            </Box>
          </Grid>
        </Grid>
      </Box>
      
      {/* {JSON.stringify(eventDetail, null, 4)} */}
      {/* <Box>
        {commentDetail && Array.isArray(commentDetail) && commentDetail.length > 0 ? (
          <>
            <Typography>Comments:</Typography>
            {commentDetail.map(comment => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </>
        ) : (
          <Typography>No comments available</Typography>
        )}
    </Box> */}
    <BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={handleClose}
				handleConfirm={handleConfirmDelete}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={commentsQuery.isLoading}
			/>
    <Listing
				// rows={commentDetail}
				columns={columns}
        fetchRecords={fetchForums}
				// handleCellClick={handleCellClick}
				// loading={commentsQuery.isLoading }
        queryName={queryNames.FORUMS}
			/>

    
    </Box>
    
  );
 
}

export default ForumDetailPage;
