import React, { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";

const styles = {
  backgroundColor: "#fff",
  borderRadius: "12px",
  color: "#333",
  fontFamily: "'Rubik Variable', sans-serif",

  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#FCFCFC",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&.Mui-selected": {
      backgroundColor: "#FCFCFC",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: "#767D90",
  },
  "&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
    borderColor: "#F1F1F1",
  },
  "& .MuiDataGrid-footerContainer": {
    justifyContent: "center",
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none",
  },
  "& .MuiDataGrid-virtualScroller": {
    minHeight: 100,
  },
  pagination: {
    mt: 4,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: "transparent", // Add background color to all page numbers
      border: "none",
      margin: "0 20px",
      color: "#000",
      fontSize: 17,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "&  .MuiPaginationItem-previousNext": {
      width: 40,
      height: 40,
      backgroundColor: "#2B2A69",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#2B2A69",
        color: "#FFF",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FFF",
        color: "#B8B8B8",
        border: "1px solid #AEAEAE",
      },
      "& svg": {
        height: 25,
        width: 25,
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      color: "#293277",
    },
  },
};

function Pagination({ page, onPageChange, className, count, limit }) {
  return (
    <MuiPagination
      className={className}
      page={page + 1}
      count={Math.ceil(count / limit)}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      variant="outlined"
      shape="rounded"
      sx={styles.pagination}
    />
  );
}

export default function Listing({
  rows = [],
  columns,
  className,
  handleCellClick,
  fetchRecords,
  queryName,
  loading = false,
  mode = false,
  search,
  isSearch,
}) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    const { status, message, data } = await fetchRecords(
      paginationModel.page,
      paginationModel.pageSize,
      search,
      isSearch
    );
    if (status) {
      return data;
    } else {
      // enqueueSnackbar(message, { variant: "error" });
    }
  };

  const { isLoading, data, isFetching } = useQuery(
    [queryName, paginationModel.page, search, isSearch],
    fetchData,
    { enabled: !mode, keepPreviousData: true }
  );

	return (
		<div style={{ width: "100%" }}>
			<DataGrid
				rows={mode ? rows : data?.data || []}
				columns={columns}
				sx={styles}
				disableRowSelectionOnClick
				className={className}
				loading={mode ? loading : isLoading || isFetching}
				disableColumnMenu
				hideFooter={true}
				paginationMode={!mode ? "server" : "client"}
				slots={
					{
						// noRowsOverlay: CustomNoRowsOverlay,
					}
				}
				slotProps={{
					pagination: {
						page: paginationModel.page,
						onPageChange: (event, newPage) => {
							setPaginationModel({ ...paginationModel, page: newPage });
						},
						count: data?.totalCount || 0,
					},
				}}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
				}
				onCellClick={handleCellClick}
			/>
			{!mode && (
				<Pagination
					page={paginationModel.page}
					onPageChange={(event, newPage) =>
						setPaginationModel({ ...paginationModel, page: newPage })
					}
					// count={data?.totalCount || 0}
					count={data?.total || data?.totalCount || 0}
					limit={paginationModel.pageSize}
				/>
			)}
		</div>
	);
}
