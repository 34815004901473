import React, { useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

import {
  ADMIN_GET_ALL_POSTS,
  ADMIN_GET_POST_BY_ID,
  ADMIN_POST_CREATE,
  ADMIN_POST_UPDATE,
  ADMIN_POST_DELETE,
  ADMIN_DELETE_POST_ASSET,
} from "../../../data/constants/apiRoutes";
import { get, post, destroy } from "../../../server";
import BoxModal from "../../../components/Modals/BoxModal";
import PageHeader from "../../../components/ui/PageHeader";
import FormInput from "../../../components/Forms/FormInput";
import CropImage from "../../../components/Modals/CropImage";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import PostSchema from "../../../utils/schemas/PostSchema";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { validator } from "../../../utils/helpers/validator";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import queryNames from "../../../data/constants/queryNames";
import Listing from "../../../components/ui/Listing";
import GoBackButton from "../../../components/Buttons/GoBackButton";

//SVG
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";

const styles = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& h3": {
      fontSize: 16,
      fontWeight: 600,
      color: "#000",
      mb: 1,
    },
  },
  eventImageContainer: {
    borderRadius: "4px",
    border: "1px dashed var(--light-background-color-2, #E2E3E4)",
    bgcolor: "#FBFCFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 2,
    p: 3,
    height: 200,
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
  coverImageContainer: {
    borderRadius: "4px",
    border: "1px solid #eee",
    height: 200,
    width: "100%",
    objectFit: "cover",
  },
  boxContainer: {
    borderRadius: "4px",
    border: "1px solid #eee",
    height: 220,
    width: "90%",
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
  coverHover: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    gap: "10px",
    transition: "display 0.3s ease",
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
};

//INITIAL STATE
const initialState = {
  description: "",
  image: "",
};

const Post = ({ event, eventId, handleInvalidate }) => {
  //USESTATE
  const [newPost, setNewPost] = useState(initialState);
  const [postData, setPostData] = useState(null);
  // const [index, setIndex] = useState(null);
  const [modal, setModal] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [cover_image, setCoverImage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isCoverHovered, setIsCoverHovered] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteAssetModal, setDeleteAssetModal] = useState(false);

  //USEREF
  const coverRef = useRef(null);

  //NAVIGATION
  const navigate = useNavigate();

  //COLUMNS FOR GRID
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.description,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 180,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "commentCount",
      headerName: "Comment Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.commentCount,
    },
    {
      field: "favCount",
      headerName: "Favourite Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.favCount,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Edit" arrow placement="top">
              <IconButton onClick={() => handleEditModalOpen(params?.row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setPostId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //ANY CHANGE IN FORM HANDLED BY THIS METHOD
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setNewPost({ ...newPost, [name]: value });
  };

  //FORM VALIDATION
  const validateForm = () => {
    const newErrors = validator(newPost, PostSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    if (!newPost?.image && !isEdit) {
      enqueueSnackbar("Please attach Cover Image", { variant: "error" });
      return false;
    }
    return true;
  };

  //ADDING IMAGE METHOD AFTER WHICH CROPIMAGE MODAL OPENS
  const handleCoverChange = (e) => {
    const file = e?.target?.files[0];
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    if (file?.size <= maxSizeInBytes) {
      setCoverImage(URL.createObjectURL(file));
      setCropModal("eventPost");
    } else {
      alert("Selected cover image exceeds the 15MB size limit.");
    }
  };

  // CLICKING ON POST TO NAVIGATE TO POSTDETAIL SCREEN
  const handleClick = (data) => {
    if (data?.field === "full_name") {
      navigate(`/events/detail/resources/${eventId}/posts/${data.id}/edit`);
    }
  };

  //MODAL OPENING AND API CALLING FOR PARTICULAR POST
  const handleEditModalOpen = async (row) => {
    setPostId(row?.id);

    setNewPost({
      description: row.description,
      image: "",
    });

    const { status, data } = await get(`${ADMIN_GET_POST_BY_ID}/${row?.id}`);
    if (status) {
      setPostData(data);
    }
    setIsEdit(true);
    setModal(true);
  };

  // MODALS OPEN/CLOSE METHODS
  const handleOpenModal = () => {
    setModal(true);
  };

  const handleClose = () => {
    setNewPost(initialState);
    setCoverImage(null);
    setPostData(null);
    setIsEdit(false);
    setModal(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setPostId(null);
  };

  const handleDeleteAssetModalClose = () => {
    setAssetId(null);
    // setIndex(null);
    setDeleteAssetModal(false);
  };

  //IMAGE HOVERING METHODS
  const handleCoverMouseEnter = () => {
    setIsCoverHovered(true);
  };

  const handleCoverMouseLeave = () => {
    setIsCoverHovered(false);
  };

  //CALLING METHOD TO GET POSTS OF EVENT
  const fetchPosts = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_ALL_POSTS}/${eventId}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //API METHODS

  const savePost = async () => {
    const payload = _.cloneDeep(newPost);
    let newStatus = true;

    const data = await awsFileUpload(payload.image, uploadImageType.createPost);
    if (data.status === true) payload.image = data.location;
    else {
      enqueueSnackbar("Error uploading", { variant: "error" });
      newStatus = false;
    }

    if (newStatus) {
      const { status, message } = await post(ADMIN_POST_CREATE, {
        event_id: event?.id,
        description: payload?.description,
        assets: [
          {
            asset_url: payload?.image,
            asset_type: "image",
          },
        ],
      });

      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const updatePost = async () => {
    const payload = _.cloneDeep(newPost);
    let newStatus = true;

    if (payload?.image) {
      const data = await awsFileUpload(
        payload.image,
        uploadImageType.createPost
      );
      if (data.status === true) payload.image = data.location;
      else {
        enqueueSnackbar("Error uploading", { variant: "error" });
        newStatus = false;
      }
    }

    if (newStatus) {
      const { status, message } = await post(ADMIN_POST_UPDATE, {
        post_id: postId,
        event_id: event.id,
        description: payload.description,
        ...(payload.image !== "" && {
          assets: [
            {
              asset_url: payload.image,
              asset_type: "image",
            },
          ],
        }),
      });

      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const deletePost = async () => {
    const { status, message } = await destroy(`${ADMIN_POST_DELETE}/${postId}`);

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const deleteAsset = async () => {
    // if (!assetId) {
    //   setNewPost((prevPost) => {
    //     const updatedAssets = [...prevPost.assets];
    //     updatedAssets.splice(index, 1);
    //     return { ...prevPost, assets: updatedAssets };
    //   });
    //   enqueueSnackbar(
    //     "Asset ID is not available. Asset may not have been submitted yet.",
    //     {
    //       variant: "warning",
    //     }
    //   );
    //   handleDeleteAssetModalClose();
    //   return;
    // }
    const { status, message } = await destroy(
      `${ADMIN_DELETE_POST_ASSET}/${postId}/asset/${assetId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      setPostData((prevPost) => {
        const updatedAssets = prevPost.assets.filter(
          (asset) => asset.id !== assetId
        );
        return { ...prevPost, assets: updatedAssets };
      });
      handleDeleteAssetModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //MUTATIONS AND ITS CALLING METHOD

  const mutation = useMutation(savePost);
  const mutationUpdate = useMutation(updatePost);
  const mutationDelete = useMutation(deletePost);
  const mutationAssetDelete = useMutation(deleteAsset);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (isEdit) {
      mutationUpdate.mutate();
    } else {
      mutation.mutate();
    }
  };

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  const handleDeleteAssetConfirm = () => {
    mutationAssetDelete.mutate();
  };

  //Return

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Posts" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
          <PageHeader
            createBtn
            createBtnText="Add Post"
            btnFnc={handleOpenModal}
          />
        </Box>
      </Box>

      <Listing
        columns={columns}
        handleCellClick={handleClick}
        queryName={queryNames.POSTS}
        fetchRecords={fetchPosts}
        className="posts"
      />
      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2">
            {!isEdit ? "Add new Post" : "Update Post"}
          </Typography>
          <Grid container spacing={3} mb={2}>
            <Grid item md={12} sm={12}>
              <FormInput
                label="Description"
                placeholder="Description"
                value={newPost.description}
                name="description"
                onChange={handleChange}
                multiline
                rows={4}
                error={errors?.description}
                helperText={errors?.description || ""}
              />
            </Grid>
          </Grid>
          <FormLabel label="Cover Image" />
          {!newPost?.image && !postData?.assets.length ? (
            <Box sx={styles.eventImageContainer}>
              <Typography component="label">+Add Cover Image</Typography>
              <Button
                variant="outlined"
                onClick={() => coverRef.current.click()}
              >
                Browse Image
              </Button>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Box>
          ) : postData?.assets.length ? (
            <Box position="relative">
              {Array.isArray(postData?.assets) &&
                postData?.assets.length > 0 &&
                postData?.assets.map((asset, index) => (
                  <Box key={index} position="relative">
                    {isEdit && (
                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() => {
                            setAssetId(asset?.id);
                            // setIndex(index);
                            setDeleteAssetModal(true);
                          }}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "red", // Adjust the color as needed
                          }}
                        >
                          <Delete sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    )}

                    <img
                      src={
                        typeof asset?.asset_url === "string"
                          ? asset?.asset_url
                          : URL.createObjectURL(asset?.asset_url)
                      }
                      style={{
                        ...styles.coverImageContainer,
                        opacity: isCoverHovered ? 0.3 : 1,
                        transition: "opacity 0.3s ease",
                      }}
                      loading="lazy"
                    />
                  </Box>
                ))}
              {newPost.image !== "" ? (
                <Box
                  component="img"
                  src={
                    typeof newPost.image === "string"
                      ? newPost.image
                      : URL.createObjectURL(newPost.image)
                  }
                  sx={{
                    ...styles.coverImageContainer,
                  }}
                  loading="lazy"
                />
              ) : null}

              <Stack>
                <Typography component="label">+Add Cover Image</Typography>
                <Button
                  variant="outlined"
                  onClick={() => coverRef.current.click()}
                >
                  Browse Image
                </Button>
              </Stack>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Box>
          ) : (
            <Box
              position="relative"
              onMouseEnter={handleCoverMouseEnter}
              onMouseLeave={handleCoverMouseLeave}
            >
              <Box
                component="img"
                src={
                  typeof newPost.image === "string"
                    ? newPost.image
                    : URL.createObjectURL(newPost.image)
                }
                sx={{
                  ...styles.coverImageContainer,
                  opacity: isCoverHovered ? 0.3 : 1,
                  transition: "opacity 0.3s ease",
                }}
                loading="lazy"
              />
              <Stack
                sx={{
                  ...styles.coverHover,
                  display: isCoverHovered ? "flex" : "none", // Control visibility
                  opacity: isCoverHovered ? 1 : 0.5, // Control opacity
                }}
              >
                <Typography component="label">+Add Cover Image</Typography>
                <Button
                  variant="outlined"
                  onClick={() => coverRef.current.click()}
                >
                  Browse Image
                </Button>
              </Stack>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Box>
          )}

          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={
              mutation.isLoading ||
              mutationUpdate.isLoading ||
              mutationAssetDelete.isLoading
            }
            sx={styles.button}
          >
            {!isEdit ? "Submit" : "Update"}
          </LoadingButton>
        </Box>

        <CropImage
          modal={cropModal}
          setModal={setCropModal}
          image={cover_image}
          user={newPost}
          setUser={setNewPost}
          ratio={16 / 9}
        />
      </Drawer>

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this post"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this asset"}
        open={deleteAssetModal}
        handleClose={handleDeleteAssetModalClose}
        handleConfirm={handleDeleteAssetConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export default Post;
